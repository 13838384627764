import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/UI/Layout";

interface ErrorProps {
  error: string;
}

const errorMessage = (error: string) => {
  if (error === "u") {
    return (
      <p style={{ margin: "20px" }}>
        This page is under construction. Please check back later.
      </p>
    );
  } else if (error === "n") {
    return (
      <p style={{ margin: "20px" }}>
        This page cannot be currently displayed due to server/connection issues.
        Please check back later.
      </p>
    );
  } else {
    return (
      <p style={{ margin: "20px" }}>
        This page does not exist or has been removed. For any queries, please
        contact us at: admin@smitenvirosolutions.in
      </p>
    );
  }
};

const Error: React.FC<ErrorProps> = ({ error }) => {
  return (
    <Layout>
      <Helmet>
        <title>SMIT Enviro Solutions | Error</title>
      </Helmet>
      <h1 style={{ margin: "20px" }}>Webpage Unavailable</h1>

      {errorMessage(error)}
    </Layout>
  );
};

export default Error;
