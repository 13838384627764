import React from "react";
import classes from "./Container.module.css";

interface ContainerProps {
  children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ children }) => {
  return <div className={classes.descriptionContainer}>{children}</div>;
};

export default Container;
