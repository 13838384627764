import React from "react";
import classes from "./Container.module.css";

interface CardProps {
  icon: React.ReactElement;
  titleText: string;
  description: Array<string>;
}

const Card: React.FC<CardProps> = ({ icon, titleText, description }) => {
  return (
    <section className={classes.card}>
      <article className={classes.cardTitleContainer}>
        {React.cloneElement(icon, { className: classes.largeIcon })}
        <span className={classes.cardTitle}>{titleText}</span>
      </article>
      <article style={{ textAlign: "justify" }}>
        {description.map((i, index) => {
          return <p key={titleText + index}>{i}</p>;
        })}
      </article>
    </section>
  );
};

export default Card;
