import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";

import Layout from "../components/UI/Layout";

import Heading from "../components/UI/Heading";
import Card from "../components/UI/Card";

import products from "../assets/products.jpg";

import { GiChimney, GiModernCity } from "react-icons/gi";
import { FcFlowChart, FcTouchscreenSmartphone } from "react-icons/fc";
import { FaTreeCity } from "react-icons/fa6";
import { TbPresentationAnalytics } from "react-icons/tb";

const Products: React.FC = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1000;

  useEffect(() => {
    const handleResizeWindow = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [width]);

  return (
    <Layout>
      <Helmet>
        <title>SMIT Enviro Solutions | Products</title>
      </Helmet>
      <Heading
        titleText="Products and Services"
        titleDescription="SMIT Enviro Solutions specializes in research and analytics for environmental management and disaster mitigation."
        subTitleText="Environmental Impact Informatics"
        subTitleDescription="Robust and accurate data analytics for environmental assessment"
      />
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <img
          src={products}
          alt="products"
          style={{
            width: width > breakpoint ? "45vw" : "65vw",
            margin: "20px",
          }}
        />
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <li>
            <Card
              icon={<GiModernCity />}
              titleText="Emission Inventories for Megacities"
              description={[
                "• Robust emission inventory reports for air pollution monitoring",
                "• Detailed assessments of sector-wise pollutant loads",
                "• Up-to-date listing of emission factors",
                "• Guidelines for air quality policy documents",
              ]}
            />
          </li>
          <li>
            <Card
              icon={<GiChimney />}
              titleText="Air Quality Management Informatics"
              description={[
                "• Sector-specific spatio-temporal projections of air pollutant levels",
                "• Forecasting models based on historical air quality and weather datasets",
                "• Region-specific prediction of air pollution",
                "• Assessment of long-range transport of air pollutants",
              ]}
            />
          </li>
          <li>
            <Card
              icon={<FcFlowChart />}
              titleText="Decision Support Informatics"
              description={[
                "• Source-level interventions for mitigating severe air pollution",
                "• Cost-effective nature-based strategies for environmental management",
              ]}
            />
          </li>
          <li>
            <Card
              icon={<FaTreeCity />}
              titleText="Action Plans for Megacities"
              description={[
                "• Environmental monitoring systems for megacities",
                "• Environmental action plans for air quality management in megacities",
              ]}
            />
          </li>
          <li>
            <Card
              icon={<TbPresentationAnalytics />}
              titleText="Market analysis for weather, hydro-meteorology, and air quality informatics"
              description={[
                "• Market analysis of latest scientific research on predominant seasonal weather parameters and their cascading impact",
              ]}
            />
          </li>
          <li>
            <Card
              icon={<FcTouchscreenSmartphone />}
              titleText="Market analysis of weather, hydro-meteorological, and air quality apps"
              description={[
                "• Market analysis of currently available web and mobile apps pertaining to weather, air quality, and hydrometeorological parameters",
              ]}
            />
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default Products;
