import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import Description from "../components/UI/Description";
import Section from "../components/UI/Section";
import Heading from "../components/UI/Heading";
import List from "../components/UI/List";
import Card from "../components/UI/Card";

import { LuFactory } from "react-icons/lu";
import { FaRegNewspaper, FaLeaf, FaChartArea } from "react-icons/fa";
import { GiLightningShadow, GiCircleForest } from "react-icons/gi";
import { TbBuildingFactory } from "react-icons/tb";

const Home: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>SMIT Enviro Solutions | Home</title>
      </Helmet>
      <Description
        subTitleText="Environmental Impact Informatics"
        subTitleDescription="Data-driven environmental assessment and disaster-risk management"
        titleText="Welcome to SMIT Enviro Solutions"
        description={[
          "We specialize in environmental assessment and disaster-risk mitigation.",
          "Our domain of work includes weather, air quality, and climate change research. Our expertise in weather simulations, air quality modelling, and nature-based environmental remedies is directed towards providing robust solutions for sustainable development.",
        ]}
      />
      <Container>
        <List
          titleText="Environmental Pollution Assessment"
          icon={<LuFactory />}
          list={[
            "Emission Inventories",
            "Environmental Status Scenarios",
            "Air Pollution Management Strategies",
            "Decision Support Informatics",
            "Environmental Management Plans",
          ]}
        />
        <List
          titleText="Environmental Management Research"
          icon={<FaRegNewspaper />}
          list={[
            "Economic analysis of environmental disasters",
            "Latest technological trends in sustainable development",
            "Regional developments in environmental management",
            "Competitive benchmarking for environmental solutions",
            "Mitigation strategies for carbon equivalent emissions",
          ]}
        />
        <List
          titleText="Sustainable Development Strategies"
          icon={<FaLeaf />}
          list={[
            "Reduction of carbon-based pollutants",
            "Pollution control measures for electrical power plants and industrial units",
            "Filtration of ozone, nitrogen dioxide, and sulfur dioxide",
            "Reduction of urban heat island intensity",
          ]}
        />
        <List
          titleText="Renewable Energy Informatics"
          icon={<FaChartArea />}
          list={[
            "Stable power generation from renewable energy sources",
            "Steps towards carbon neutrality",
            "Historical trends in availability of renewable energy sources",
            "Analysis of climate data and energy distribution patterns to determine availability of renewable resources",
          ]}
        />
      </Container>
      <Section
        titleText="Environment Management Strategies"
        subTitleText="Assessment of Disaster Impacts"
        description={[
          "Anthropogenic pollution and natural disasters destabilize ecological equilibrium.",
          "SMIT Enviro Solutions develops strategies to mitigate impacts of ecological disasters by assessing environmental conditions, studying historical climate trends, analyzing pollutant levels, and predicting environmental hazards.",
          "We aim to minimize detrimental impacts of natural disasters and anthropogenic pollution to promote ecological stability.",
        ]}
      />
      <hr></hr>
      <Heading
        titleText="Disaster Impact Prediction and Mitigation Strategies"
        titleDescription=""
        subTitleText="Environmental Assessment Services"
        subTitleDescription="Environmental modelling for disaster impact reduction and
            sustainable development"
      />
      <Container>
        <Card
          titleText="Natural Environmental Catastrophes"
          icon={<GiLightningShadow />}
          description={[
            "Natural disasters such as floods, lightning strikes, and wildfires cause catastrophic environmental damage and severely disrupt human life.",
            "SMIT Enviro Solutions specializes in assessment of extant environmental conditions and analysis of historical climate trends to develop action plans for response, rescue, and mitigation operations.",
          ]}
        />
        <Card
          titleText="Anthropogenically Induced Environmental Hazards"
          icon={<TbBuildingFactory />}
          description={[
            "Anthropogenic activities such as use of fossil fuels and large-scale factory operations cause air pollution, leading to environmental damage and health hazards.",
            "SMIT Enviro Solutions provides air quality management and pollution mitigation strategies based on monitoring and assessment of regional pollutant levels.",
          ]}
        />
        <Card
          titleText="Nature-Based Environmental Management"
          icon={<GiCircleForest />}
          description={[
            "Nature-based strategies for environmental management are based on using plants and microbial agents to replenish forest cover, neutralize pollutants, prevent floods, etc.",
            "SMIT Enviro Solutions facilitates implemention of nature-based environment management strategies to build sustainable environments.",
          ]}
        />
      </Container>
    </Layout>
  );
};

export default Home;
