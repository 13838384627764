import React from "react";
import classes from "./Container.module.css";

interface DescriptionProps {
  subTitleText: string;
  subTitleDescription: string | React.ReactElement;
  titleText: string;
  description: Array<string | React.ReactElement>;
}

const Description: React.FC<DescriptionProps> = ({
  subTitleText,
  subTitleDescription,
  titleText,
  description,
}) => {
  return (
    <div className={classes.descriptionContainer}>
      <section className={classes.mainDescriptionHeading}>
        <article className={classes.title}>{subTitleText}</article>
        <article className={classes.descriptionText}>
          {subTitleDescription}
        </article>
        <hr></hr>
      </section>
      <section className={classes.mainDescription}>
        <article className={classes.title}>{titleText}</article>
        <article className={classes.descriptionText}>
          {description.map((i, index) => {
            return (
              <p key={titleText + subTitleText + subTitleDescription + index}>
                {i}
              </p>
            );
          })}
        </article>
      </section>
    </div>
  );
};

export default Description;
