import React from "react";
import { Helmet } from "react-helmet";
// import { useNavigate } from "react-router-dom";

import Layout from "../components/UI/Layout";
import Heading from "../components/UI/Heading";
import Section from "../components/UI/Section";

import info from "../assets/info.jpg";
import nature from "../assets/nature.jpg";

// import { CgLink } from "react-icons/cg";

const Info: React.FC = () => {
  // const navigate = useNavigate();

  return (
    <Layout>
      <Helmet>
        <title>SMIT Enviro Solutions | Info</title>
      </Helmet>
      <Heading
        titleText="Environmental Impact Informatics"
        titleDescription="The impact of environmental disasters on life and property is significantly dependent on response time of rescue and mitigation services. Efficient prediction and warning systems greatly reduce damage resulting from environmental catastrophes."
        subTitleText="Environmental Monitoring and Assessment"
        subTitleDescription="Cloud-based AI-ML architecture with IoT integration for predicting impacts of environmental changes"
      />
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <img
          src={info}
          alt="workflow"
          style={{
            width: "90%",
            borderRadius: "20px",
            border: "3px solid rgba(26, 21, 21, 0.744)",
            outline: "4px solid rgb(20, 16, 16, 0.7)",
          }}
        />
      </section>
      <Section
        titleText=""
        subTitleText=""
        description={[
          "SMIT Enviro Solutions develops action plans based on environmental monitoring and assessment aided by cloud-based technologies for data analytics.",
          "The 24x7 highly available cloud data-storage layer receives sensor data from various sources and processes it through scalable compute systems that employ AI / ML models to predict catastrophic events resulting from climate variations.",
        ]}
      />
      <hr></hr>
      <Section
        titleText="Environment Management Solutions"
        subTitleText="Nature-Based Strategies"
        description={[
          "SMIT Enviro Solutions specializes in developing nature-based strategies for environmental management and disaster mitigation.",
          "Nature-based strategies involve use of plants and microbes to have restorative environmental impacts such as:",
          "• Absorption and dispersion of pollutants",
          "• Diversion of flood waters",
          "• Prevention of forest fires",
          "• Maintenance of soil fertility",
          "• Groundwater level restoration",
        ]}
      />
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <img
          src={nature}
          alt="nature-based strategies"
          style={{
            width: "90%",
            borderRadius: "20px",
            border: "3px solid rgba(26, 21, 21, 0.744)",
            outline: "4px solid rgb(20, 16, 16, 0.7)",
          }}
        />
      </section>
      <hr style={{ margin: "20px" }}></hr>
      {/* <section
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <button
          style={{
            color: "white",
            background: "none",
            borderRadius: "20px",
            padding: "4px 8px 4px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/literature")}
        >
          List of Publications {"(Link)"}
          <CgLink />
        </button>
      </section> */}
      <hr style={{ margin: "20px" }}></hr>
    </Layout>
  );
};

export default Info;
