import React from "react";
import classes from "./Container.module.css";

interface HeadingProps {
  titleText: string;
  titleDescription: string;
  subTitleText: string;
  subTitleDescription: string;
}

const Heading: React.FC<HeadingProps> = ({
  titleText,
  titleDescription,
  subTitleText,
  subTitleDescription,
}) => {
  return (
    <div className={classes.descriptionContainer}>
      <section className={classes.mainDescriptionHeading}>
        <article className={classes.title}>{subTitleText}</article>
        <article className={classes.descriptionText}>
          {subTitleDescription}
        </article>
        <hr></hr>
      </section>
      <section className={classes.mainDescription}>
        <article className={classes.title}>
          <h1 style={{ color: "white" }}>{titleText}</h1>
        </article>
        <article className={classes.descriptionText}>
          {titleDescription}
        </article>
      </section>
    </div>
  );
};

export default Heading;
