import React from "react";
import classes from "./Container.module.css";

interface SectionProps {
  titleText: string;
  subTitleText: string;
  description: Array<string | React.ReactElement>;
}

const Section: React.FC<SectionProps> = ({
  titleText,
  subTitleText,
  description,
}) => {
  return (
    <div className={classes.descriptionContainer}>
      <article className={classes.title}>
        <h2>{titleText}</h2>
        <h3 style={{ color: "white" }}>{subTitleText}</h3>
        <hr></hr>
      </article>
      <article className={classes.descriptionText}>
        {description.map((i, index) => {
          return <p key={titleText + subTitleText + index}>{i}</p>;
        })}
      </article>
    </div>
  );
};

export default Section;
