import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/UI/Layout";

import Description from "../components/UI/Description";

import { CiMail } from "react-icons/ci";
import { ImProfile } from "react-icons/im";
import { RiExternalLinkLine } from "react-icons/ri";

const Contact: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>SMIT Enviro Solutions | Contact</title>
      </Helmet>
      <Description
        subTitleText="Contact"
        subTitleDescription={
          <React.Fragment>
            <section
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <a
                href="https://sites.google.com/view/dr-dilip-chate/bio"
                target="_blank"
                style={{
                  fontFamily: "inherit",
                  color: "white",
                  textUnderlineOffset: "4px",
                }}
                rel="noreferrer"
              >
                <ImProfile style={{ margin: "5px 5px 0px" }} />
                Research Head:&nbsp;Dr. Dilip Chate
                <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
              </a>
              <a
                href="mailto:admin@smitenvirosolutions.in"
                style={{
                  fontFamily: "inherit",
                  color: "white",
                  textUnderlineOffset: "4px",
                  padding: "3px 0px",
                }}
              >
                <CiMail style={{ margin: "5px 5px 0px" }} />
                Email:&nbsp;admin@smitenvirosolutions.in
                <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
              </a>
            </section>
          </React.Fragment>
        }
        titleText=""
        description={[
          "Contact us for location-specific environmental assessment, disaster impact mitigation strategies, and nature-based preventive and remedial measures for sustainable development.",
        ]}
      />
    </Layout>
  );
};

export default Contact;
